import { parse } from "@aws-sdk/util-arn-parser";
import * as Sentry from "@sentry/browser";

import { TableLink } from "./TableLink";

interface Props {
  resourceKey: string;
  resourceName?: string;
}

export function ResourceLink({ resourceKey, resourceName }: Props) {
  let resource;

  try {
    const arn = parse(resourceKey);
    resource = arn.resource;
  } catch (e) {
    resource = resourceKey;
    Sentry.captureException(e);
  }

  return (
    <TableLink
      copyValue={resourceKey}
      description={resourceKey}
      name={resourceName || resource}
      to={`/cloudops/resources/${encodeURIComponent(resourceKey)}`}
      copyButton
    />
  );
}
